"use strict";

import { BROWSER, ELEMENT_POSITION, CATEGORY_MARGIN_WIDTH } from "./constants";

/**
 * アクセスデバイスの閲覧状態が横長であるかをブラウザ表示サイズで判断します
 * @returns
 */
export function isLandscape() {
  // 横が縦より長ければ横長と判定（正方形は横長と扱う）
  return window.innerWidth >= window.innerHeight;
}

/**
 * モニタ表示エリア内の指定された位置に位置するエレメントを取得します
 * @param {string} position - ELEMENT_POSITION
 * @param {number} [customPosition] - "custom" 位置を使用する場合に指定するピクセル位置
 * @returns {Element|null}
 */
export function getElementAtPosition(position, customPosition = 0) {
  const windowWidth = window.innerWidth;
  let targetPosition;

  switch (position) {
    case ELEMENT_POSITION.CENTER:
      targetPosition = window.scrollX + windowWidth / 2;
      break;
    case ELEMENT_POSITION.LEFT:
      targetPosition = window.scrollX + CATEGORY_MARGIN_WIDTH;
      break;
    case ELEMENT_POSITION.RIGHT:
      targetPosition = window.scrollX + windowWidth;
      break;
    case ELEMENT_POSITION.CUSTOM:
      targetPosition = window.scrollX + customPosition;
      break;
    default:
      throw new Error("無効な position 引数です。ELEMENT_POSITION を指定してください。");
  }

  const elements = document.querySelectorAll(".category");

  for (let element of elements) {
    const rect = element.getBoundingClientRect();
    const elementStart = rect.left + window.scrollX;
    const elementEnd = rect.right + window.scrollX;

    if (elementStart <= targetPosition && targetPosition <= elementEnd) {
      return element;
    }
  }

  return null;
}
