"use strict";

const modalWrapper = document.getElementById("modal-wrapper");

// モーダルでポップアップ表示するための関数
function showModal() {
  modalSizeAdjustment();
  window.addEventListener("resize", modalSizeAdjustment);
  modalWrapper.classList.add("visible");
}

// モーダルを非表示にするための関数
function hideModal() {
  modalWrapper.classList.remove("visible");
  modalWrapper.classList.remove("hidden");
}

// モーダルのサイズ調整関数
function modalSizeAdjustment() {
  const entranceElement = modalWrapper.querySelector(".entrance");
  const entranceBgElement = modalWrapper.querySelector(".entrance-bg");
  const entranceHeadElement = modalWrapper.querySelector(".entrance-bg-head");
  entranceElement.style.marginTop = `${entranceHeadElement.clientHeight}px`;
  entranceElement.style.height = `${entranceBgElement.clientHeight - entranceHeadElement.clientHeight}px`;
}

// 初回訪問かチェックする関数
function checkFirstVisit() {
  return sessionStorage.getItem("isFirstVisited") === null;
}

// クリックされた時の処理を設定する関数
function setupOkButtonClick() {
  document.querySelectorAll(".modal-ok-button").forEach(function (element) {
    element.addEventListener("click", function () {
      sessionStorage.setItem("isFirstVisited", true);
      hideModal();
    });
  });
}

// ページの読み込みが完了した時に実行する処理
window.addEventListener("load", function () {
  if (!modalWrapper) return;

  // モーダルでポップアップ表示
  if (checkFirstVisit()) {
    showModal();
  } else {
    hideModal();
  }

  setupOkButtonClick();
});
