"use strict";

const { defaultNameResolver } = require("webpack/lib/NamedChunksPlugin");

let orderCnt = 1;

/**
 * 'book_order' クラスを持つ全ての要素の value 属性を初期化します
 */
function initializeBookOrderValues() {
  document.querySelectorAll(".book_order").forEach((element) => {
    element.value = "";
  });
}

/**
 * 'book_title' クラスのクリックイベントを設定します
 */
function setupBookTitleClickEvents() {
  document.querySelectorAll(".book_row").forEach((row) => {
    const textElement = row.querySelector(".book_title");
    textElement.addEventListener("click", () => handleBookTitleClick(row));
  });
}

/**
 * 'book_title' 要素がクリックされたときの処理
 * @param {HTMLElement} row クリックされた行の要素
 */
function handleBookTitleClick(row) {
  const orderElement = row.querySelector(".book_order");
  if (orderElement.value == "") {
    orderElement.value = orderCnt;
    orderElement.textContent = orderCnt;
    orderCnt++;
  }
}

// 初期化関数の呼び出し
initializeBookOrderValues();
setupBookTitleClickEvents();
