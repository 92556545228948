"use strict";

const ConcatenatedModule = require("webpack/lib/optimize/ConcatenatedModule");

/**
 * 'selected-check-box' クラスを持つ全てのチェックボックスを選択または解除します
 * @param {HTMLElement} ele チェック状態を基準とする要素
 */
window.changeChecked = function (ele) {
  document.querySelectorAll(".selected-check-box").forEach((checkBox) => {
    checkBox.checked = ele.checked;
  });
};

/**
 * 'selected-check-box' クラスかつチェックされているチェックボックスの bookId を JSON 文字列として
 * 'hidden_book_id' ID の要素の value 属性に設定します
 */
window.getDeletedBookIds = function () {
  const checkboxes = document.querySelectorAll(".selected-check-box:checked");
  const ids = Array.from(checkboxes, (checkbox) => checkbox.dataset.bookId);
  document.getElementById("hidden_book_id").value = JSON.stringify(ids);
};

/**
 * 表示・非表示（本の詳細）を切り替える非同期リクエストを送信します
 * @param {HTMLElement} ele チェック状態を基準とする要素
 */
window.changeDisplayDetails = function (ele) {
  sendAjaxRequest("/admin/books/update_display_details", {
    book_id: ele.dataset.bookId,
    display_details: ele.checked,
  });
};

/**
 * 表示・非表示（本）を切り替える非同期リクエストを送信します
 * @param {HTMLElement} ele チェック状態を基準とする要素
 */
window.changeIsDisplayed = function (ele) {
  sendAjaxRequest("/admin/books/update_is_displayed", {
    book_id: ele.dataset.bookId,
    is_displayed: ele.checked,
  });
};

/**
 * カテゴリへの簡易追加・解除を切り替える非同期リクエストを送信します
 * @param {HTMLElement} ele チェック状態を基準とする要素
 */
window.changeAddOrRemoveCategory = function (ele) {
  sendAjaxRequest("/admin/books/add_or_remove_category", { book_id: ele.dataset.bookId, category_id: ele.dataset.defaultCategoryId, is_added: ele.checked }, function (data) {
    // 表示条件のセレクトボックス：カテゴリの値を取得
    const category = document.getElementById('selected_category_id');
    
    // チェックボックスがfalseかつ、表示条件のカテゴリとデフォルト表示のカテゴリが同じ場合は、表示条件に該当しない行なので非表示にする
    if (!ele.checked && category?.value == ele.dataset.defaultCategoryId) {
      const td = ele.parentNode;
      const tr = td.parentNode;
      tr.style.display = 'none';
    } else {
      const book_categories = document.getElementById("book-id-" + ele.dataset.bookId + "-categories");
      book_categories.innerHTML = categories;
    }
  });
};

/**
 * Ajaxリクエストを送信する関数
 * @param {string} url リクエスト先URL
 * @param {object} data 送信するデータ
 * @param {function} successCallback 成功時のコールバック関数
 * @param {function} errorCallback エラー時のコールバック関数
 */
function sendAjaxRequest(url, data, successCallback, errorCallback) {
  $.ajax({
    type: "PATCH",
    url: url,
    data: data,
    success: successCallback || function (data) {},
    error: errorCallback || function (error) { console.error("Request failed:", error); },
  });
}
