"use strict";

// カスタムリンク表示
export const SORT_ORDER_CUSTOM = 5;

// カテゴリの余幅（調整値）
export const CATEGORY_MARGIN_WIDTH = 1;

// 詳細表の高さの基準値
export const DEFAULT_IMAGE_HEIGHT = 1050;

// アニメーションの時間設定（ミリ秒）
export const ANIMATION_DURATION = {
  POSITION: 800,
  CATEGORY_OPEN: 200,
};

// 多階層種別
export const MULTI_LAYER = Object.freeze({
  JAPANESE_ORDER: 3, // 五十音順
  CONTRIBUTOR: 4, // 推薦者
  BOOK: 6, // 本
});

// moveAnimation(), getElementAtPosition() で使用する位置種別
export const ELEMENT_POSITION = Object.freeze({
  CENTER: 0,
  LEFT: 1,
  RIGHT: 2,
  CUSTOM: 3,
});
