"use strict";

import { defaultNameResolver } from "webpack/lib/NamedChunksPlugin";
import ConcatenatedModule from "webpack/lib/optimize/ConcatenatedModule";
import * as util from "./util";
import * as constants from "./constants";

// 本棚エレメント
const container = document.querySelector(".categories");

// サイト設定
const siteSettings = document.getElementById("site-settings");

// アニメーション中のクリック制御
let isClickable = true;

// DOMツリー読み込み完了後の初期化処理
document.addEventListener("DOMContentLoaded", init);

/**
 * 初期化処理です
 */
function init() {
  if (!siteSettings) return;

  // サーバから受取る共通定義の存在チェック
  if (!gon || !gon.alignment) location.href = "/500.html";

  console.log("load");
  loadCategory();
  handleResize();
  setClickEventModalButtonLinks();
  setClickEventCategory();
  setClickEventMoveLeftRightBtn();

  window.addEventListener("resize", handleResize);
  container.addEventListener("wheel", handleVerticalScroll);
  handleLoaderAnimationCompletion();

  // 画面全体で右クリックからのコンテキストメニューを非表示
  document.addEventListener("contextmenu", (e) => e.preventDefault());
}

/**
 * リサイズイベント時に全てのカテゴリと本の要素の調整、左右ボタンの表示/非表示を制御します
 */
function handleResize() {
  document.querySelectorAll(".category").forEach(resizeCategory);
  document.querySelectorAll(".book").forEach((book) => resizeBook(book.closest(".books").closest(".category"), book));

  const moveBtnId = document.getElementById("move-btn");
  const alignment = util.isLandscape() ? siteSettings.dataset.sideControlLandscape : siteSettings.dataset.sideControlPortrait;
  moveBtnId.classList.toggle("hidden", alignment == gon.alignment.NONE);
}

/**
 * カテゴリをロードして表示を更新（展開）します
 */
function loadCategory() {
  const categoryId = container?.getAttribute("load-category_id");

  // トップページへのアクセスかカテゴリへのアクセスかのチェック
  if (!categoryId) return;

  const element = document.getElementById(categoryId);
  const eleLink = element.querySelector(".category_spine_link");
  const newUrl = eleLink.getAttribute("href");

  getUrlData(element, newUrl, true);
}

/**
 * 指定された階層のルールに基づき、指定の要素をアニメーションで移動させます
 * @param {string} targetLayer - 移動する階層を示す定数
 * @param {HTMLElement} targetElement - 移動対象の要素
 * @param {string} alignment - 寄せ位置の指定
 */
function moveAnimation(targetLayer, targetElement, alignment = gon.alignment.ONLY_CENTERING) {
  // 指定された寄せ位置が無効な場合は処理しない
  if (alignment == gon.alignment.NONE) return;

  // 要素の中央にスクロールするためのスクロール位置を計算
  function getCenterPosition(element) {
    const containerRect = container.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();
    return elementRect.left + container.scrollLeft - (containerRect.left + containerRect.width / 2) + elementRect.width / 2;
  }

  // 要素の左端にスクロールするためのスクロール位置を計算
  function getLeftPosition(element) {
    const containerRect = container.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();
    return elementRect.left + container.scrollLeft - containerRect.left;
  }

  let targetScroll = null;

  // 対象の階層に応じてスクロール位置を設定
  switch (targetLayer) {
    case constants.MULTI_LAYER.CONTRIBUTOR:
      if (alignment == gon.alignment.OPEN_CENTERING || alignment == gon.alignment.ONLY_CENTERING) {
        targetScroll = getCenterPosition(targetElement);
      } else {
        targetScroll = getLeftPosition(targetElement);
      }
      break;
    case constants.MULTI_LAYER.BOOK:
      targetScroll = getCenterPosition(targetElement);
      break;
    default:
      return;
  }

  let startTime = null;

  // イージング関数（時間 t に応じてイージングされた値を計算して返却）
  function easeInOutCubic(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t * t + b;
    t -= 2;
    return (c / 2) * (t * t * t + 2) + b;
  }

  // アニメーションのフレームごとに実行される関数
  function step(timestamp) {
    if (!startTime) startTime = timestamp;
    const progress = timestamp - startTime;
    // イージング関数を使ってスクロール位置を計算
    const scrollX = easeInOutCubic(progress, container.scrollLeft, targetScroll - container.scrollLeft, constants.ANIMATION_DURATION.POSITION);
    container.scrollLeft = scrollX;
    // アニメーションの持続時間が経過していなければ次のフレームを要求
    if (progress < constants.ANIMATION_DURATION.POSITION) {
      requestAnimationFrame(step);
    }
  }

  // アニメーションを開始
  requestAnimationFrame(step);
}

/**
 * カテゴリのサイズを調整します
 * @param {HTMLElement} container - コンテナ要素
 */
function resizeCategory(container) {
  const img = container.querySelector(".spine-image");
  const marginTopPx = container.getAttribute("data-margin-top");
  setSpineHeight(img, container.clientHeight, marginTopPx);

  // 余白追加
  container.style.paddingTop = `${marginTopPx}px`;

  // コンテナ内の全ての詳細要素に対して処理を実行
  container.querySelectorAll(".category-detail").forEach((element) => {
    element.style.height = img.style.height;
    setDetailWidth(element, element, container.clientHeight);
  });
}

/**
 * 本のサイズを調整します
 * @param {HTMLElement} container
 * @param {HTMLElement} element
 */
function resizeBook(container, element) {
  // ブラウザの表示領域と詳細表の高さの基準値から比率を計算
  const clientHeight = container.clientHeight;
  const rate = clientHeight / constants.DEFAULT_IMAGE_HEIGHT;
  const marginTopPx = Math.floor(rate * element.getAttribute("data-margin-top"));

  // 本の背表紙の高さを計算
  const bookSpine = element.querySelector(".spine-image");
  setSpineHeight(bookSpine, container.clientHeight, marginTopPx);

  // 本の詳細の高さを設定
  const bookDetail = element.querySelector(".book-detail");
  bookDetail.style.height = bookSpine.style.height;

  // 詳細の横幅を設定
  setDetailWidth(bookDetail, element, container.clientHeight);
}

/**
 * 詳細の幅を設定します
 * @param {HTMLElement} detailElement - 幅を設定する詳細の要素
 * @param {HTMLElement} getAttributeElement - data 属性の data-detail-width が設定されている要素
 * @param {number} clientHeight - class='category' の要素
 */
function setDetailWidth(detailElement, getAttributeElement, clientHeight) {
  const viewportWidth = document.body.clientWidth * 0.7;
  const rate = clientHeight / constants.DEFAULT_IMAGE_HEIGHT;
  const detailWidth = Math.floor(rate * getAttributeElement.getAttribute("data-detail-width"));

  detailElement.style.width = `${Math.min(viewportWidth, detailWidth)}px`;
}

/**
 * 背表紙画像の高さを設定します
 * @param {HTMLElement} spineElement - 表紙画像の要素
 * @param {number} clientHeight - ブラウザの表示領域
 * @param {number} marginTop - 表紙上部のマージンサイズ
 */
function setSpineHeight(spineElement, clientHeight, marginTop) {
  const spineHeight = clientHeight - marginTop;
  spineElement.style.height = `${spineHeight}px`;
}

/**
 * 内部リンクのイベントディスパッチ
 * @param {*} internalLinkElement
 */
function setInternalDispatchEvent(internalLinkElement) {
  const internalLink = internalLinkElement.getAttribute("href");

  // 内部リンクに対応するカテゴリの背表紙リンク要素を取得
  const categorySpineLinkElement = document.querySelector(`.category_spine_link[href="${internalLink}"]`);

  // デバイスの向きに応じた内部リンク制御設定を取得
  const internalLinkControlSetting = () => (util.isLandscape() ? siteSettings.dataset.internalLinkControlLandscape : siteSettings.dataset.internalLinkControlPortrait);

  // クリックイベントハンドラ
  const handleClick = (event) => {
    event.preventDefault(); // デフォルトのリンク動作をキャンセル
    event.stopPropagation(); // イベントのバブリングを停止

    // リンク先カテゴリ要素を取得し、詳細表示の状態を確認
    const categoryLinkElement = categorySpineLinkElement?.parentElement;
    const detail = categoryLinkElement?.querySelector(".books");
    const isOpen = detail && detail.style.maxWidth !== "";

    // パンくずリスト経由のアクセスか判定
    const isFromBrowsingHistory = internalLinkElement.classList.contains("history");

    // カテゴリが開かれているか、または移動のみの制御設定が適用されている場合
    if (isOpen || [gon.alignment.ONLY_LEFT_ALIGNMENT, gon.alignment.ONLY_CENTERING].includes(internalLinkControlSetting())) {
      const linkElement = isFromBrowsingHistory ? internalLinkElement : categorySpineLinkElement;
      updateAndAnimate(linkElement, categoryLinkElement, internalLinkControlSetting());
      return;
    }

    // パンくずリスト（履歴）経由でアクセスされた場合
    if (isFromBrowsingHistory) {
      // カテゴリ詳細と本の表示
      toggleCategoryDetail(categoryLinkElement, categoryLinkElement.querySelector(".category-info"));
      toggleBooks(detail);
      updateAndAnimate(internalLinkElement, categoryLinkElement, internalLinkControlSetting());
      return;
    }

    // 閉じているカテゴリを開いて表示
    const newUrl = categorySpineLinkElement.getAttribute("href");
    getUrlData(categoryLinkElement, newUrl, internalLinkControlSetting());
  };

  /**
   * パンくずリストの更新とアニメーション処理を実行
   * @param {*} linkElement
   * @param {*} categoryLinkElement
   * @param {*} controlSetting
   */
  const updateAndAnimate = (linkElement, categoryLinkElement, controlSetting) => {
    const spineImageElement = categoryLinkElement.querySelector(".spine-image");
    updateHistoryElement(linkElement); // パンくずリストを更新
    moveAnimation(constants.MULTI_LAYER.CONTRIBUTOR, spineImageElement, controlSetting); // アニメーションを実行
    handleResize(); // リサイズ処理を実行
  };

  // クリックイベントを設定
  internalLinkElement.addEventListener("click", handleClick);
}

/**
 * 内部リンクのクリックイベントを設定します
 * @param {HTMLElement} categoryInfoElement - カテゴリ情報要素
 * @param {HTMLElement} booksElement - 本の要素
 */
function setClickEventInternalLinks(categoryInfoElement, booksElement) {
  // カテゴリ詳細の内部リンクを設定する
  categoryInfoElement.querySelectorAll('.category-detail a[href^="/"]').forEach(setInternalDispatchEvent);

  // 本の詳細の内部リンクを設定する
  booksElement.querySelectorAll('.book-detail a[href^="/"]').forEach(setInternalDispatchEvent);
}

/**
 * モーダル上の内部リンクのクリックイベントを設定します
 */
function setClickEventModalButtonLinks() {
  // モーダルの内部リンクを設定する
  const modalWrapper = document.getElementById("modal-wrapper");
  modalWrapper?.querySelectorAll('.entrance a[href^="/"]').forEach(setInternalDispatchEvent);
}

/**
 * カテゴリ背表紙のクリックイベントを設定します
 */
function setClickEventCategory() {
  document.querySelectorAll(".category").forEach(function (element) {
    const eleLink = element.querySelector(".category_spine_link");
    const newUrl = eleLink.getAttribute("href");

    eleLink.addEventListener("click", function (event) {
      history.replaceState(null, "", newUrl); // 履歴を更新してURLを変更する
      event.preventDefault(); // デフォルトのクリック動作をキャンセル

      if (!isClickable) return; // アニメーション中はクリックを無効化（200ms）

      // デバイスの向きに応じたカテゴリ制御設定を取得
      const categoryControlSetting = util.isLandscape() ? siteSettings.dataset.categoryControlLandscape : siteSettings.dataset.categoryControlPortrait;

      // URLからカテゴリデータを取得して表示する
      getUrlData(element, newUrl, categoryControlSetting);
    });
  });
}

/**
 * カテゴリ背表紙の左右移動ボタンのクリックイベントを設定します
 */
function setClickEventMoveLeftRightBtn() {
  const moveIdElement = document.querySelector("#move-btn");
  const buttons = moveIdElement.querySelectorAll(".image-btn");

  buttons.forEach((button) => {
    const isLeftArrow = button.firstElementChild.classList.contains("left-arrow");
    const isRightArrow = button.firstElementChild.classList.contains("right-arrow");

    if (isLeftArrow || isRightArrow) {
      button.addEventListener("click", (event) => {
        event.preventDefault(); // デフォルトのクリック動作をキャンセル

        // デバイスの向きに応じたサイドコントロール設定を取得
        const sideControlSetting = util.isLandscape() ? siteSettings.dataset.sideControlLandscape : siteSettings.dataset.sideControlPortrait;

        // 移動対象の位置を設定
        const position = sideControlSetting == gon.alignment.ONLY_CENTERING || sideControlSetting == gon.alignment.OPEN_CENTERING ? constants.ELEMENT_POSITION.CENTER : constants.ELEMENT_POSITION.LEFT;

        // 指定位置のエレメントを取得
        let positionElement = util.getElementAtPosition(position);
        if (!positionElement) return;

        // 左矢印がクリックされた場合の特別な処理（隣接するカテゴリに直ちに移動しないように制御）
        if (isLeftArrow) {
          const categorySpine = positionElement.querySelector(".category_spine_link");
          const rect = categorySpine.getBoundingClientRect();
          const elementStart = rect.left + window.scrollX;
          const elementEnd = rect.right + window.scrollX;

          const isCentered = position == constants.ELEMENT_POSITION.CENTER;
          const targetPosition = isCentered ? window.scrollX + window.innerWidth / 2 : window.scrollX;
          const isOutOfBounds = isCentered ? !(elementStart <= targetPosition && targetPosition <= elementEnd) : elementStart + constants.CATEGORY_MARGIN_WIDTH <= targetPosition;

          // 背表紙の幅に指定位置が収まっていない場合、次のカテゴリに移動する
          if (isOutOfBounds) {
            positionElement = positionElement.nextElementSibling;
          }
        }

        // 移動先のエレメントを決定
        let targetElement = isLeftArrow ? positionElement.previousElementSibling : positionElement.nextElementSibling;
        if (!targetElement) return;

        // サイドコントロール設定でカテゴリを開くかどうかを確認
        if (sideControlSetting == gon.alignment.OPEN_LEFT_ALIGNMENT || sideControlSetting == gon.alignment.OPEN_CENTERING) {
          const booksElement = targetElement.querySelector(".books");
          const isOpen = booksElement.style.maxWidth != "";

          // カテゴリが閉じている場合、カテゴリを表示
          if (!isOpen) {
            const linkElement = targetElement.querySelector(".category_spine_link");
            const newUrl = linkElement.getAttribute("href");
            getUrlData(targetElement, newUrl, sideControlSetting);
            return;
          }
        }

        // 移動先の背表紙エレメントを取得
        targetElement = targetElement.querySelector(".category_spine_link");

        // パンくずリスト更新
        updateHistoryElement(targetElement);

        // アニメーションを実行
        moveAnimation(constants.MULTI_LAYER.CONTRIBUTOR, targetElement, sideControlSetting);
      });
    }
  });
}

/**
 * カテゴリクリック時にデータを取得して表示します
 * @param {*} element - .category の要素
 * @param {*} newUrl - .category_spine_link 要素の href 属性の値
 * @param {*} alignment - 移動アニメーションの寄せ位置
 */
function getUrlData(element, newUrl, alignment) {
  // 要素の取得
  const eleSpineImage = element.querySelector(".spine-image");
  const eleCategoryInfo = element.querySelector(".category-info");
  const eleBooks = element.querySelector(".books");

  // カテゴリの詳細情報と本がすでに読み込まれているかチェック
  if (!element.classList.contains("data-loaded")) {
    // ローダーを表示して読み込み開始
    const loader = document.querySelector("#loader");
    loader.className = "";
    loader.classList.add("loading");

    // Ajaxでデータを取得
    $.ajax({
      type: "GET",
      url: newUrl,
      dataType: "script",
      beforeSend: function () {
        // リクエスト送信前の処理
      },
      success: function () {
        // 成功時の処理：HTMLを挿入し、各種イベントを設定
        eleCategoryInfo.innerHTML = cateInfoHTML;
        eleBooks.innerHTML = booksHTML;

        toggleCategoryDetail(element, eleCategoryInfo);
        toggleBooks(eleBooks);

        // 移動アニメーションの実行
        if (alignment != gon.alignment.NONE) {
          moveAnimation(constants.MULTI_LAYER.CONTRIBUTOR, eleSpineImage, alignment);
        }

        // 本のクリックイベント設定と内部リンク設定
        setClickEventBook(eleBooks);
        setClickEventInternalLinks(eleCategoryInfo, eleBooks);

        // パンくずリスト更新
        updateHistoryElement(eleSpineImage.parentElement);

        // 画面のリサイズ処理
        handleResize();
      },
      error: function (data) {
        // エラー発生時の処理
      },
      complete: function () {
        // ローディング画面を停止
        loader.classList.add("loaded");

        // カテゴリの詳細、本情報を読み込み済みとしてマーク
        element.classList.add("data-loaded");
      },
    });
  } else {
    // 読み込み済みの場合は表示を切り替えるのみ
    toggleCategoryDetail(element, eleCategoryInfo);
    toggleBooks(eleBooks);

    const isOpen = eleBooks.style.maxWidth != "";

    // 移動アニメーションの実行
    if (alignment != gon.alignment.NONE && isOpen) {
      // 開いている場合のみ移動アニメーションを実行
      moveAnimation(constants.MULTI_LAYER.CONTRIBUTOR, eleSpineImage, alignment);
    }

    if (isOpen) {
      // パンくずリスト更新
      updateHistoryElement(eleSpineImage.parentElement);
    }

    // 画面のリサイズ処理
    handleResize();
  }
}

/**
 * カテゴリの詳細表示を切り替えます
 * （.category の data-display-detail の値が true の場合表示します）
 * @param {HTMLElement} categoryElement - .category の要素
 * @param {HTMLElement} categoryInfoElement - .category-info の要素
 */
function toggleCategoryDetail(categoryElement, categoryInfoElement) {
  const shouldDisplayDetails = categoryElement.getAttribute("data-display-detail") == "true";
  const detailElement = categoryInfoElement.querySelector(".category-detail");

  if (shouldDisplayDetails) {
    // カテゴリの詳細が表示される場合
    const isOpen = categoryInfoElement.style.maxWidth != "";
    categoryInfoElement.style.maxWidth = isOpen ? "" : detailElement.getAttribute("data-detail-width") + "px"; // 詳細を開閉するアニメーション
  } else {
    // 詳細が非表示の場合
    detailElement.style.display = "none";
  }
}

/**
 * カテゴリに登録されている本の表示切り替えを実行します
 * @param {HTMLElement} booksElement - .category要素内の.books要素
 */
function toggleBooks(booksElement) {
  const isOpen = booksElement.style.maxWidth != "";

  // 本の詳細をすべて閉じる関数
  function closeAllBookDetails(booksElement) {
    booksElement.querySelectorAll(".book-detail").forEach((detail) => {
      detail.style.display = "none";
    });
    booksElement.style.maxWidth = "";
    booksElement.style.display = "none";
  }

  // 本の詳細をすべて開く関数
  function openAllBookDetails(booksElement) {
    let booksWidth = 0;
    // 本の全幅（背表紙のみ）を取得
    booksElement.querySelectorAll(".book").forEach((detail) => {
      booksWidth += detail.offsetWidth;
    });

    booksElement.style.display = "flex";
    if (booksWidth != 0) {
      booksElement.style.maxWidth = booksWidth + "px";
      isClickable = false;
      setTimeout(() => {
        booksElement.style.maxWidth = "none"; // 本の詳細表示の考慮
        isClickable = true;
      }, constants.ANIMATION_DURATION.CATEGORY_OPEN); // アニメーション時間待機
    } else {
      booksElement.style.maxWidth = "none";
    }
  }

  if (isOpen) {
    // 本の詳細をすべて閉じる
    closeAllBookDetails(booksElement);
  } else {
    // 本の詳細を開く
    openAllBookDetails(booksElement);
  }
}

/**
 * 本の背表紙のクリックイベントを設定します
 * （.category の data-display-detail の値が true の場合表示）
 * @param {HTMLElement} booksElement - .category要素内の.books要素
 */
function setClickEventBook(booksElement) {
  //本の詳細表示を切り替える関数
  function toggleBookDetail(booksElement) {
    const bookDetail = booksElement.querySelector(".book-detail");

    if (bookDetail.style.display == "block") {
      bookDetail.style.display = "none";
    } else {
      bookDetail.style.display = "block";
      // クリックした本が中央になるようにスクロール
      moveAnimation(constants.MULTI_LAYER.BOOK, bookDetail);
    }
  }

  booksElement.querySelectorAll(".book").forEach((eleBook) => {
    const bookSpine = eleBook.querySelector(".spine-image");
    const showDetail = eleBook.getAttribute("data-display-details");

    if (showDetail == "true") {
      bookSpine.addEventListener("click", () => {
        toggleBookDetail(eleBook);
      });
    }
  });
}

/**
 * 閲覧（パンくず）リストを更新します
 * @param {HTMLElement} targetLinkElement - 履歴更新時の対象要素
 */
function updateHistoryElement(targetLinkElement) {
  let isUpdate = true;
  if (targetLinkElement.classList.contains("history")) isUpdate = false;

  const breadcrumbElement = document.getElementById("breadcrumb");
  const breadcrumbOlElement = breadcrumbElement.firstElementChild;

  // selected クラス削除
  breadcrumbOlElement.childNodes.forEach((liElement) => liElement.classList.remove("selected"));

  if (isUpdate) {
    // 最終閲覧履歴と更新対象の一致判定
    const lastHistory = breadcrumbOlElement.lastElementChild?.firstChild.getAttribute("href");
    if (lastHistory == targetLinkElement.getAttribute("href")) {
      // 最後尾の対象要素に selected クラス追加
      breadcrumbOlElement.lastElementChild.classList.add("selected");
      // パンくずの右寄せ処理（再描画後）
      requestAnimationFrame(() => (breadcrumbOlElement.scrollLeft = breadcrumbOlElement.scrollWidth));
      return;
    }

    // 履歴要素の作成
    const liTagElement = document.createElement("li");
    liTagElement.classList.add("selected");
    const aTagElement = document.createElement("a");
    aTagElement.setAttribute("href", targetLinkElement.getAttribute("href"));
    aTagElement.classList.add("history");
    aTagElement.textContent = targetLinkElement.getAttribute("data-display-name");

    // クリック時の動作登録
    setInternalDispatchEvent(aTagElement);

    // 要素追加
    liTagElement.appendChild(aTagElement);
    breadcrumbOlElement.appendChild(liTagElement);

    // パンくずの右寄せ処理（再描画後）
    requestAnimationFrame(() => (breadcrumbOlElement.scrollLeft = breadcrumbOlElement.scrollWidth));
  } else {
    // 対象要素に selected クラス追加
    breadcrumbOlElement.childNodes.forEach((liElement) => {
      if (liElement == targetLinkElement.closest("li")) liElement.classList.add("selected");
    });
  }
}

/**
 * 子要素がスクロール可能な親要素内に存在するかを検出します
 * @param {HTMLElement} childElement - 子要素
 * @param {HTMLElement} parentElement - 親要素
 * @returns {boolean} - 子要素がスクロール可能な親要素内に存在する場合はtrue、そうでない場合はfalse
 */
function isChildInScrollableParent(childElement, parentElement) {
  let currentElement = childElement;

  // 要素が縦スクロール可能かどうかを判定する関数
  function isHeightScrollable(element) {
    return element.scrollHeight > element.clientHeight;
  }

  // 子要素が親要素の中にある限り、上位の親要素に対してスクロール可能かどうかをチェックする
  while (currentElement && currentElement != parentElement) {
    if (isHeightScrollable(currentElement)) {
      return true;
    }
    currentElement = currentElement.parentElement;
  }

  // 最終的に親要素自体がスクロール可能かどうかをチェックする
  return isHeightScrollable(parentElement);
}

/**
 * 縦スクロールを横スクロールとして処理します
 * @param {WheelEvent} event - スクロールイベント
 */
function handleVerticalScroll(event) {
  // 横スクロールイベントは処理しない
  if (Math.abs(event.deltaY) < Math.abs(event.deltaX)) return;

  // マウスが指している要素が縦スクロール可能なエリアに含まれているか確認
  const mouseHoverElement = document.elementFromPoint(event.clientX, event.clientY);
  if (isChildInScrollableParent(mouseHoverElement, container)) return;

  // 縦スクロールイベントを停止して、移動量を横スクロールに変換
  event.preventDefault();
  container.scrollLeft += event.deltaY;
}

/**
 * ロードアニメーションの完了を監視し、完了した場合に処理を実行します
 */
function handleLoaderAnimationCompletion() {
  // ローダー要素を取得
  const loader = document.getElementById("loader");

  // ローダー要素が存在しない場合は処理しない
  if (!loader) {
    return;
  }

  // アニメーションが完了した時の処理を設定
  loader.addEventListener("animationend", function () {
    // アニメーションが完了した後の処理をここに記述する
    loader.className = "";
  });
}
